var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg-light-grey text-left my-2" },
    [
      _c(
        "div",
        {
          staticClass:
            "grid grid-cols-12 gap-x-6 p-2 border-dashed border-b border-brown-grey",
        },
        [
          _c("p", { staticClass: "col-start-1 col-span-3 text-right" }, [
            _vm.isAgreed
              ? _c("img", {
                  staticClass: "w-6 h-6 float-left",
                  attrs: { src: _vm.okIcon[_vm.$i18n.locale] },
                })
              : !_vm.hiddenIcon
              ? _c("img", {
                  staticClass: "w-6 h-6 float-left hover:bg-brown-grey",
                  attrs: {
                    src: _vm.ngIcon[_vm.$i18n.locale],
                    title:
                      _vm.$i18n.locale !== "ja"
                        ? "alternative spec, counter offer from the license holder"
                        : "",
                  },
                })
              : _vm._e(),
            _c("span", [_vm._v(_vm._s(_vm.title))]),
          ]),
          _c("p", { staticClass: "col-span-7" }, [_vm._v(_vm._s(_vm.outline))]),
          _c("div", { staticClass: "col-start-11 col-span-2 text-right" }, [
            _vm.hasSlotDefault
              ? _c("button", { staticClass: "inline align-middle" }, [
                  !_vm.openState
                    ? _c("img", {
                        staticClass: "w-6 h-6",
                        attrs: {
                          src: require("@/assets/image/open_square_icon.svg"),
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            _vm.isOpen = true
                          },
                        },
                      })
                    : _c("img", {
                        staticClass: "w-6 h-6",
                        attrs: {
                          src: require("@/assets/image/close_square_icon.svg"),
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            _vm.isOpen = false
                          },
                        },
                      }),
                ])
              : _vm._e(),
          ]),
        ]
      ),
      _vm.openState
        ? [_c("div", { staticClass: "p-2" }, [_vm._t("default")], 2)]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }