var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-screen" },
    [
      _c("div", { staticClass: "container mx-auto mb-10" }, [
        _c("div", { staticClass: "w-full flex items-center justify-between" }, [
          _c("div", { staticClass: "flex items-center" }, [
            _vm.quote
              ? _c("h3", { staticClass: "mt-3" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("pages.commons.quotes.estimate")) +
                      " - " +
                      _vm._s(_vm.quoteTitle) +
                      " "
                  ),
                  _c("img", {
                    staticClass: "w-8 h-8 mx-2 inline",
                    attrs: {
                      src: require("@/assets/image/negotiating_icon.svg"),
                    },
                  }),
                ])
              : _vm._e(),
          ]),
          _vm.quote && _vm.quote.created_at && !_vm.isQuoteSet
            ? _c(
                "div",
                { staticClass: "text-right font-bold col-span-1 mt-3" },
                [
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.$t("pages.commons.quotes.request_at")) +
                        " " +
                        _vm._s(_vm._f("date")(_vm.quote.created_at))
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "clear-both" },
          _vm._l(_vm.quoteMovies, function (qm, quoteIndex) {
            return _c(
              "div",
              { key: "quote-movie-" + quoteIndex, staticClass: "mb-2" },
              [
                _c(
                  "QuoteMovieViewItem",
                  {
                    attrs: {
                      title: _vm.getQMTitleWithState(qm, _vm.loginUserRole),
                      isOK: !_vm.hasDiff(quoteIndex),
                      bottomTitle: _vm.validUntilToString(qm),
                      hidesTrash: !_vm.displayTrash,
                      disableTrash: _vm.plannedDeleteIndex !== -1,
                      hiddenIcon: _vm.isQuoteSet,
                    },
                    on: {
                      delete: function ($event) {
                        return _vm.planDelete(quoteIndex)
                      },
                    },
                  },
                  [
                    _vm.quote.pricing_type === 1
                      ? _c("TermsCompareItem", {
                          attrs: {
                            title: _vm.$t("commons.fields.payment_type"),
                            outline: _vm.$t("pages.commons.quotes.flat_rate"),
                            original: _vm.quote,
                            compareKeys: ["flat_price"],
                            target: qm,
                            hiddenIcon: _vm.isQuoteSet,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid grid-cols-12 gap-x-6 mb-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-3 text-right",
                                          },
                                          [_vm._v(_vm._s(_vm.vodRequestTitle))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-span-9" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.localeCurrencyString(
                                                  _vm.quote,
                                                  _vm.quote.flat_price,
                                                  _vm.loginUserRole
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid grid-cols-12 gap-x-6 mb-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-3 text-right",
                                          },
                                          [_vm._v(_vm._s(_vm.chResponseTitle))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-span-9" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.localeCurrencyString(
                                                  _vm.quote,
                                                  qm.flat_price,
                                                  _vm.loginUserRole
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      : _vm._e(),
                    _vm.quote.pricing_type === 2
                      ? _c("TermsCompareItem", {
                          attrs: {
                            title: _vm.$t("commons.fields.payment_type"),
                            outline: _vm.$t(
                              "pages.commons.quotes.revenue_share"
                            ),
                            original: _vm.quote,
                            compareKeys: [
                              "revenue_percent",
                              "price_minimum_guarantee",
                            ],
                            target: qm,
                            hiddenIcon: _vm.isQuoteSet,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid grid-cols-12 gap-x-6 mb-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-3 text-right",
                                          },
                                          [_vm._v(_vm._s(_vm.vodRequestTitle))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-span-9" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "pages.commons.quotes.revenue_share_detail",
                                                    [
                                                      _vm.revenuePercent(qm),
                                                      _vm.localeCurrencyString(
                                                        _vm.quote,
                                                        _vm.quote.revenue_min,
                                                        _vm.loginUserRole
                                                      ),
                                                      _vm.localeCurrencyString(
                                                        _vm.quote,
                                                        _vm.quote.revenue_max,
                                                        _vm.loginUserRole
                                                      ),
                                                    ]
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _vm.quote.price_minimum_guarantee
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-start-4 col-span-9",
                                              },
                                              [
                                                _vm._v(
                                                  " (" +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "pages.commons.quotes.price_minimum_guarantee"
                                                      )
                                                    ) +
                                                    ": "
                                                ),
                                                _vm.hasMG(
                                                  _vm.quote,
                                                  qm,
                                                  _vm.loginUserRole
                                                ) &&
                                                _vm.loginUserRole === "consumer"
                                                  ? [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.consumerMGPriceString(
                                                              _vm.quote,
                                                              qm,
                                                              true
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  : [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.providerMGPriceString(
                                                              _vm.quote,
                                                              qm,
                                                              true
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ],
                                                _vm._v(
                                                  " / " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        _vm.mgDuration[
                                                          _vm.quote
                                                            .period_minimum_guarantee -
                                                            1
                                                        ]
                                                      )
                                                    ) +
                                                    ") "
                                                ),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid grid-cols-12 gap-x-6 mb-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-3 text-right",
                                          },
                                          [_vm._v(_vm._s(_vm.chResponseTitle))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-span-9" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "pages.commons.quotes.revenue_share_detail",
                                                    [
                                                      _vm.revenuePercent(qm),
                                                      _vm.localeCurrencyString(
                                                        _vm.quote,
                                                        _vm.quote.revenue_min,
                                                        _vm.loginUserRole
                                                      ),
                                                      _vm.localeCurrencyString(
                                                        _vm.quote,
                                                        _vm.quote.revenue_max,
                                                        _vm.loginUserRole
                                                      ),
                                                    ]
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-start-4 col-span-9",
                                          },
                                          [
                                            _vm._v(
                                              " (" +
                                                _vm._s(
                                                  _vm.$t(
                                                    "pages.commons.quotes.price_minimum_guarantee"
                                                  )
                                                ) +
                                                ": "
                                            ),
                                            _vm.hasMG(
                                              _vm.quote,
                                              qm,
                                              _vm.loginUserRole
                                            ) &&
                                            _vm.loginUserRole === "consumer"
                                              ? [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.consumerMGPriceString(
                                                          _vm.quote,
                                                          qm
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              : [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.providerMGPriceString(
                                                          _vm.quote,
                                                          qm
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                            _vm._v(
                                              " / " +
                                                _vm._s(
                                                  _vm.$t(_vm.mgDuration[1])
                                                ) +
                                                ") "
                                            ),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      : _vm._e(),
                    _vm.quote.pricing_type === 3
                      ? _c("TermsCompareItem", {
                          attrs: {
                            title: _vm.$t("commons.fields.payment_type"),
                            outline: _vm.$t(
                              "pages.commons.quotes.viewer_count"
                            ),
                            original: _vm.quote,
                            compareKeys: ["price_minimum_guarantee"],
                            target: qm,
                            hiddenIcon: _vm.isQuoteSet,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid grid-cols-12 gap-x-6 mb-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-3 text-right",
                                          },
                                          [_vm._v(_vm._s(_vm.vodRequestTitle))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-span-9" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "pages.commons.quotes.viewer_count_detail",
                                                    [
                                                      _vm.localeCurrencyString(
                                                        _vm.quote,
                                                        _vm.quote
                                                          .price_viewer_count,
                                                        _vm.loginUserRole
                                                      ),
                                                      _vm.quote.min_secs,
                                                      _vm.quote
                                                        .viewer_count_min,
                                                      _vm.quote
                                                        .viewer_count_max,
                                                    ]
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _vm.quote.price_minimum_guarantee
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-start-4 col-span-9",
                                              },
                                              [
                                                _vm._v(
                                                  " (" +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "pages.commons.quotes.price_minimum_guarantee"
                                                      )
                                                    ) +
                                                    ": " +
                                                    _vm._s(
                                                      _vm._f("price")(
                                                        _vm.consumerMG(
                                                          _vm.quote,
                                                          qm,
                                                          _vm.loginUserRole,
                                                          false
                                                        ),
                                                        _vm.currency
                                                      )
                                                    ) +
                                                    " / " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        _vm.mgDuration[
                                                          _vm.quote
                                                            .period_minimum_guarantee -
                                                            1
                                                        ]
                                                      )
                                                    ) +
                                                    ") "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid grid-cols-12 gap-x-6 mb-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-3 text-right",
                                          },
                                          [_vm._v(_vm._s(_vm.chResponseTitle))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-span-9" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "pages.commons.quotes.viewer_count_detail",
                                                    [
                                                      _vm.localeCurrencyString(
                                                        _vm.quote,
                                                        qm.price_viewer_count,
                                                        _vm.loginUserRole
                                                      ),
                                                      _vm.quote.min_secs,
                                                      _vm.quote
                                                        .viewer_count_min,
                                                      _vm.quote
                                                        .viewer_count_max,
                                                    ]
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _vm.hasMG(
                                          _vm.quote,
                                          qm,
                                          _vm.loginUserRole
                                        ) && _vm.loginUserRole === "consumer"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-start-4 col-span-9",
                                              },
                                              [
                                                _vm._v(
                                                  " (" +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "pages.commons.quotes.price_minimum_guarantee"
                                                      )
                                                    ) +
                                                    ": " +
                                                    _vm._s(
                                                      _vm._f("price")(
                                                        _vm.providerMG(
                                                          _vm.quote,
                                                          qm,
                                                          _vm.loginUserRole
                                                        ),
                                                        _vm.currency
                                                      )
                                                    ) +
                                                    " / " +
                                                    _vm._s(
                                                      _vm.$t(_vm.mgDuration[1])
                                                    ) +
                                                    ") "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.hasMG(
                                          _vm.quote,
                                          qm,
                                          _vm.loginUserRole
                                        ) && _vm.loginUserRole === "provider"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-start-4 col-span-9",
                                              },
                                              [
                                                _vm._v(
                                                  " (" +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "pages.commons.quotes.price_minimum_guarantee"
                                                      )
                                                    ) +
                                                    ": " +
                                                    _vm._s(
                                                      _vm._f("price")(
                                                        _vm.consumerMG(
                                                          _vm.quote,
                                                          qm,
                                                          _vm.loginUserRole
                                                        ),
                                                        _vm.currency
                                                      )
                                                    ) +
                                                    " / " +
                                                    _vm._s(
                                                      _vm.$t(_vm.mgDuration[1])
                                                    ) +
                                                    ") "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      : _vm._e(),
                    _vm.quote.pricing_type === 4
                      ? _c("TermsCompareItem", {
                          attrs: {
                            title: _vm.$t("commons.fields.payment_type"),
                            outline: _vm.$t(
                              "pages.commons.quotes.stream_minute"
                            ),
                            original: _vm.quote,
                            compareKeys: [
                              "price_stream_minute",
                              "price_minimum_guarantee",
                            ],
                            target: qm,
                            hiddenIcon: _vm.isQuoteSet,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid grid-cols-12 gap-x-6 mb-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-3 text-right",
                                          },
                                          [_vm._v(_vm._s(_vm.vodRequestTitle))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-span-9" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "pages.commons.quotes.stream_minute_detail",
                                                    [
                                                      _vm.localeCurrencyString(
                                                        _vm.quote,
                                                        _vm.quote
                                                          .price_viewer_count,
                                                        _vm.loginUserRole
                                                      ),
                                                      _vm.quote
                                                        .stream_minutes_min,
                                                      _vm.quote
                                                        .stream_minutes_max,
                                                    ]
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _vm.quote.price_minimum_guarantee
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-start-4 col-span-9",
                                              },
                                              [
                                                _vm._v(
                                                  " (" +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "pages.commons.quotes.price_minimum_guarantee"
                                                      )
                                                    ) +
                                                    ": " +
                                                    _vm._s(
                                                      _vm._f("price")(
                                                        _vm.consumerMG(
                                                          _vm.quote,
                                                          qm,
                                                          _vm.loginUserRole,
                                                          false
                                                        ),
                                                        _vm.currency
                                                      )
                                                    ) +
                                                    " / " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        _vm.mgDuration[
                                                          _vm.quote
                                                            .period_minimum_guarantee -
                                                            1
                                                        ]
                                                      )
                                                    ) +
                                                    ") "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid grid-cols-12 gap-x-6 mb-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-3 text-right",
                                          },
                                          [_vm._v(_vm._s(_vm.chResponseTitle))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-span-9" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "pages.commons.quotes.stream_minute_detail",
                                                    [
                                                      _vm.localeCurrencyString(
                                                        _vm.quote,
                                                        qm.price_viewer_count,
                                                        _vm.loginUserRole
                                                      ),
                                                      _vm.quote
                                                        .stream_minutes_min,
                                                      _vm.quote
                                                        .stream_minutes_max,
                                                    ]
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _vm.hasMG(
                                          _vm.quote,
                                          qm,
                                          _vm.loginUserRole
                                        ) && _vm.loginUserRole === "consumer"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-start-4 col-span-9",
                                              },
                                              [
                                                _vm._v(
                                                  " (" +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "pages.commons.quotes.price_minimum_guarantee"
                                                      )
                                                    ) +
                                                    ": " +
                                                    _vm._s(
                                                      _vm._f("price")(
                                                        _vm.providerMG(
                                                          _vm.quote,
                                                          qm,
                                                          _vm.loginUserRole
                                                        ),
                                                        _vm.currency
                                                      )
                                                    ) +
                                                    " / " +
                                                    _vm._s(
                                                      _vm.$t(_vm.mgDuration[1])
                                                    ) +
                                                    ") "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.hasMG(
                                          _vm.quote,
                                          qm,
                                          _vm.loginUserRole
                                        ) && _vm.loginUserRole === "provider"
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-start-4 col-span-9",
                                              },
                                              [
                                                _vm._v(
                                                  " (" +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "pages.commons.quotes.price_minimum_guarantee"
                                                      )
                                                    ) +
                                                    ": " +
                                                    _vm._s(
                                                      _vm._f("price")(
                                                        _vm.consumerMG(
                                                          _vm.quote,
                                                          qm,
                                                          _vm.loginUserRole
                                                        ),
                                                        _vm.currency
                                                      )
                                                    ) +
                                                    " / " +
                                                    _vm._s(
                                                      _vm.$t(_vm.mgDuration[1])
                                                    ) +
                                                    ") "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      : _vm._e(),
                    _vm._l(_vm.quote.formats, function (format, formatIndex) {
                      return _c("TermsCompareItem", {
                        key: "format-" + formatIndex,
                        attrs: {
                          title: _vm.$t("commons.fields.movie_format_title", [
                            _vm.textOfEnum(_vm.videoType, format.video_type),
                          ]),
                          outline:
                            _vm.$t("pages.commons.quotes.resolution") +
                            ":" +
                            _vm.textOfEnum(
                              _vm.movieResolution,
                              format.resolution
                            ) +
                            " " +
                            _vm.$t("pages.commons.quotes.frame_rate") +
                            ":" +
                            _vm.textOfEnum(_vm.frameRate, format.frame_rate) +
                            " " +
                            _vm.$t("pages.commons.quotes.aspect_ratio") +
                            ":" +
                            _vm.textOfEnum(
                              _vm.aspectRatio,
                              format.aspect_ratio
                            ) +
                            " " +
                            _vm.$t(
                              "pages.commons.quotes.video_encoding_format"
                            ) +
                            ":" +
                            _vm.textOfEnum(
                              _vm.videoFormat,
                              format.video_encoding_format
                            ) +
                            " " +
                            _vm.$t("pages.commons.quotes.audio_mode") +
                            ":" +
                            _vm.textOfEnum(
                              _vm.audioFormat,
                              format.audio_format
                            ) +
                            " " +
                            _vm.$t("commons.fields.format_audio_channel") +
                            ":" +
                            (format.audio_channel === ""
                              ? "none"
                              : format.audio_channel),
                          original: format,
                          target: qm.formats[formatIndex],
                          compareKeys: [
                            "video_encoding_format",
                            "resolution",
                            "frame_rate",
                            "aspect_ratio",
                            "audio_format",
                            "audio_channel",
                          ],
                          hiddenIcon: _vm.isQuoteSet,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "grid grid-cols-12 gap-x-6 mb-2",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-span-3 text-right",
                                        },
                                        [_vm._v(_vm._s(_vm.vodRequestTitle))]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-span-9" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "pages.commons.quotes.resolution"
                                                ) +
                                                  ":" +
                                                  _vm.textOfEnum(
                                                    _vm.movieResolution,
                                                    format.resolution
                                                  ) +
                                                  " " +
                                                  _vm.$t(
                                                    "pages.commons.quotes.frame_rate"
                                                  ) +
                                                  ":" +
                                                  _vm.textOfEnum(
                                                    _vm.frameRate,
                                                    format.frame_rate
                                                  ) +
                                                  " " +
                                                  _vm.$t(
                                                    "pages.commons.quotes.aspect_ratio"
                                                  ) +
                                                  ":" +
                                                  _vm.textOfEnum(
                                                    _vm.aspectRatio,
                                                    format.aspect_ratio
                                                  ) +
                                                  " " +
                                                  _vm.$t(
                                                    "pages.commons.quotes.video_encoding_format"
                                                  ) +
                                                  ":" +
                                                  _vm.textOfEnum(
                                                    _vm.videoFormat,
                                                    format.video_encoding_format
                                                  ) +
                                                  " " +
                                                  _vm.$t(
                                                    "pages.commons.quotes.audio_mode"
                                                  ) +
                                                  ":" +
                                                  _vm.textOfEnum(
                                                    _vm.audioFormat,
                                                    format.audio_format
                                                  ) +
                                                  " " +
                                                  _vm.$t(
                                                    "commons.fields.format_audio_channel"
                                                  ) +
                                                  ":"
                                              ) +
                                              " "
                                          ),
                                          format.audio_channel === ""
                                            ? [_vm._v(" none ")]
                                            : [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      format.audio_channel
                                                    ) +
                                                    " "
                                                ),
                                              ],
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "grid grid-cols-12 gap-x-6 mb-2",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-span-3 text-right",
                                        },
                                        [_vm._v(_vm._s(_vm.chResponseTitle))]
                                      ),
                                      !_vm.isQuoteSet
                                        ? _c(
                                            "div",
                                            { staticClass: "col-span-9" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "pages.commons.quotes.resolution"
                                                    ) +
                                                      ":" +
                                                      _vm.textOfEnum(
                                                        _vm.movieResolution,
                                                        _vm.getMovieFormat(
                                                          qm.formats[
                                                            formatIndex
                                                          ],
                                                          "resolution"
                                                        )
                                                      ) +
                                                      " " +
                                                      _vm.$t(
                                                        "pages.commons.quotes.frame_rate"
                                                      ) +
                                                      ":" +
                                                      _vm.textOfEnum(
                                                        _vm.frameRate,
                                                        _vm.getMovieFormat(
                                                          qm.formats[
                                                            formatIndex
                                                          ],
                                                          "frame_rate"
                                                        )
                                                      ) +
                                                      " " +
                                                      _vm.$t(
                                                        "pages.commons.quotes.aspect_ratio"
                                                      ) +
                                                      ":" +
                                                      _vm.textOfEnum(
                                                        _vm.aspectRatio,
                                                        _vm.getMovieFormat(
                                                          qm.formats[
                                                            formatIndex
                                                          ],
                                                          "aspect_ratio"
                                                        )
                                                      ) +
                                                      " " +
                                                      _vm.$t(
                                                        "pages.commons.quotes.video_encoding_format"
                                                      ) +
                                                      ":" +
                                                      _vm.textOfEnum(
                                                        _vm.videoFormat,
                                                        _vm.getMovieFormat(
                                                          qm.formats[
                                                            formatIndex
                                                          ],
                                                          "video_encoding_format"
                                                        )
                                                      ) +
                                                      " " +
                                                      _vm.$t(
                                                        "pages.commons.quotes.audio_mode"
                                                      ) +
                                                      ":" +
                                                      _vm.textOfEnum(
                                                        _vm.audioFormat,
                                                        _vm.getMovieFormat(
                                                          qm.formats[
                                                            formatIndex
                                                          ],
                                                          "audio_format"
                                                        )
                                                      ) +
                                                      " " +
                                                      _vm.$t(
                                                        "commons.fields.format_audio_channel"
                                                      ) +
                                                      ":"
                                                  ) +
                                                  " "
                                              ),
                                              !qm.formats[formatIndex] ||
                                              qm.formats[formatIndex]
                                                .audio_channel === ""
                                                ? [_vm._v(" none ")]
                                                : [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          qm.formats[
                                                            formatIndex
                                                          ].audio_channel
                                                        ) +
                                                        " "
                                                    ),
                                                  ],
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    }),
                    _vm._l(
                      _vm.quote.languages,
                      function (language, languageIndex) {
                        return _c("TermsCompareItem", {
                          key: "language-" + languageIndex,
                          attrs: {
                            title: _vm.languageTitle(language),
                            outline: _vm.languageOutline(language),
                            target: qm.languages[languageIndex],
                            original: language,
                            compareKeys: [
                              "subtitle_language",
                              "voiceover_language",
                              "embedded_subtitle",
                              "subtitle_file_format",
                              "translation_payment",
                            ],
                            hiddenIcon: _vm.isQuoteSet,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid grid-cols-12 gap-x-6 mb-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-3 text-right",
                                          },
                                          [_vm._v(_vm._s(_vm.vodRequestTitle))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-span-9" },
                                          [
                                            language.is_subtitle
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "commons.fields.subtitles"
                                                        )
                                                      ) +
                                                      ":" +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "commons.options.language." +
                                                            language.subtitle_language
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ])
                                              : language.is_voiceover
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "commons.fields.dubbs"
                                                        )
                                                      ) +
                                                      ":" +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "commons.options.language." +
                                                            language.voiceover_language
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ])
                                              : _vm._e(),
                                            language.embedded_subtitle
                                              ? [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "pages.commons.quotes.baked"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              : [
                                                  _vm._v(
                                                    " separate file: " +
                                                      _vm._s(
                                                        _vm.textOfEnum(
                                                          _vm.subtitleFileFormat,
                                                          language.subtitle_file_format
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                            language.subtitle_language !== "en"
                                              ? [
                                                  language.translation_payment ==
                                                  1
                                                    ? [
                                                        _vm._v(
                                                          " (seller pays for translation) "
                                                        ),
                                                      ]
                                                    : [
                                                        _vm._v(
                                                          " (buyer pays for translation) "
                                                        ),
                                                      ],
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid grid-cols-12 gap-x-6 mb-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-3 text-right",
                                          },
                                          [_vm._v(_vm._s(_vm.chResponseTitle))]
                                        ),
                                        !_vm.isQuoteSet &&
                                        qm.languages[languageIndex]
                                          ? _c(
                                              "div",
                                              { staticClass: "col-span-9" },
                                              [
                                                qm.languages[languageIndex]
                                                  .is_subtitle
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "commons.fields.subtitles"
                                                            )
                                                          ) +
                                                          ":" +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "commons.options.language." +
                                                                language.subtitle_language
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ])
                                                  : qm.languages[languageIndex]
                                                      .is_voiceover
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "commons.fields.dubbs"
                                                            )
                                                          ) +
                                                          ":" +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "commons.options.language." +
                                                                qm.languages[
                                                                  languageIndex
                                                                ]
                                                                  .voiceover_language
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                qm.languages[languageIndex]
                                                  .is_subtitle
                                                  ? [
                                                      qm.languages[
                                                        languageIndex
                                                      ].embedded_subtitle
                                                        ? [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "pages.commons.quotes.baked"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        : [
                                                            _vm._v(
                                                              " separate file: " +
                                                                _vm._s(
                                                                  _vm.textOfEnum(
                                                                    _vm.subtitleFileFormat,
                                                                    qm
                                                                      .languages[
                                                                      languageIndex
                                                                    ]
                                                                      .subtitle_file_format
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ],
                                                    ]
                                                  : [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-bold text-red-600",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "pages.commons.quotes.unable_to_provide"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                language.subtitle_language !==
                                                  "en" &&
                                                (qm.languages[languageIndex]
                                                  .subtitle_language ||
                                                  qm.languages[languageIndex]
                                                    .voiceover_language)
                                                  ? [
                                                      qm.languages[
                                                        languageIndex
                                                      ].translation_payment ===
                                                      1
                                                        ? [
                                                            _vm._v(
                                                              " (seller pays for translation) "
                                                            ),
                                                          ]
                                                        : [
                                                            _vm._v(
                                                              " (buyer pays for translation) "
                                                            ),
                                                          ],
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      }
                    ),
                    _c("TermsCompareItem", {
                      attrs: {
                        title: _vm.$t("pages.commons.quotes.distribution_area"),
                        outline: _vm.countriesListing(),
                        target: _vm.quoteMovies[quoteIndex],
                        original: _vm.quote,
                        hiddenIcon: _vm.isQuoteSet,
                        compareKeys: _vm.quote.all_countries
                          ? ["countries", "all_countries"]
                          : ["countries"],
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid grid-cols-12 gap-x-6 mb-2",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "col-span-3 text-right" },
                                      [_vm._v(_vm._s(_vm.vodRequestTitle))]
                                    ),
                                    _vm.quote.all_countries &&
                                    _vm.quote.countries.length
                                      ? _c(
                                          "div",
                                          { staticClass: "col-span-9" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "pages.commons.quotes.excluding_countries"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm.quote.all_countries &&
                                        !_vm.quote.countries.length
                                      ? _c(
                                          "div",
                                          { staticClass: "col-span-9" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "pages.commons.quotes.worldwide_description_incl_japan"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.quote.countries.length
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-9 col-start-4",
                                            class: _vm.quoteMovies[quoteIndex]
                                              .all_countries
                                              ? "col-start-4"
                                              : "",
                                          },
                                          _vm._l(
                                            _vm.quote.countries,
                                            function (country, countryIndex) {
                                              return _c(
                                                "span",
                                                {
                                                  key:
                                                    "country-" + countryIndex,
                                                  staticClass:
                                                    "py-1 px-2 mr-2 mb-2 genre-tag-label text-sm text-white inline-block",
                                                },
                                                [_vm._v(_vm._s(country.label))]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid grid-cols-12 gap-x-6 mb-2",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "col-span-3 text-right" },
                                      [_vm._v(_vm._s(_vm.chResponseTitle))]
                                    ),
                                    _vm.quote.all_countries &&
                                    _vm.quoteMovies[quoteIndex].countries.length
                                      ? _c(
                                          "div",
                                          { staticClass: "col-span-9" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "pages.commons.quotes.excluding_countries"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm.quote.all_countries &&
                                        !_vm.quoteMovies[quoteIndex].countries
                                          .length
                                      ? _c(
                                          "div",
                                          { staticClass: "col-span-9" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "pages.commons.quotes.worldwide_description_incl_japan"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.quoteMovies[quoteIndex].countries.length
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-9 col-start-4",
                                            class: _vm.quoteMovies[quoteIndex]
                                              .all_countries
                                              ? "col-start-4"
                                              : "",
                                          },
                                          _vm._l(
                                            qm.countries,
                                            function (country, countryIndex) {
                                              return _c(
                                                "span",
                                                {
                                                  key:
                                                    "country-" + countryIndex,
                                                  staticClass:
                                                    "py-1 px-2 mr-2 mb-2 genre-tag-label text-sm text-white inline-block",
                                                },
                                                [_vm._v(_vm._s(country.label))]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid grid-cols-12 gap-x-6 mb-2",
                                  },
                                  [
                                    _vm.quote.countries.length !==
                                    _vm.quoteMovies[quoteIndex].countries.length
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-3 text-right",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "pages.commons.quotes.difference"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.quote.countries.length !==
                                    _vm.quoteMovies[quoteIndex].countries.length
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-9 col-start-4",
                                          },
                                          _vm._l(
                                            _vm.countryDifference(
                                              _vm.quote,
                                              _vm.quoteMovies[quoteIndex]
                                            ),
                                            function (country, countryIndex) {
                                              return _c(
                                                "span",
                                                {
                                                  key:
                                                    "country-" + countryIndex,
                                                  staticClass:
                                                    "py-1 px-2 mr-2 mb-2 genre-tag-label text-sm text-white inline-block",
                                                },
                                                [_vm._v(_vm._s(country.label))]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-9 col-start-4",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "pages.commons.quotes.same"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "mb-2 text-xs bg-off-yellow-lighter",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$i18n.t(
                                          "pages.commons.quotes.territory_note"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c("TermsCompareItem", {
                      attrs: {
                        title: _vm.$t("pages.commons.quotes.rights"),
                        outline: _vm.rightsString(_vm.quote.rights),
                        target: _vm.quoteMovies[quoteIndex].rights,
                        original: _vm.quote.rights,
                        compareKeys: [
                          "right_type",
                          "exclusive_type",
                          "holdback_length",
                          "air_run_count",
                        ],
                        hiddenIcon: _vm.isQuoteSet,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid grid-cols-12 gap-x-6 mb-2",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "col-span-3 text-right" },
                                      [_vm._v(_vm._s(_vm.vodRequestTitle))]
                                    ),
                                    _vm._l(
                                      _vm.quote.rights,
                                      function (right, rightIndex) {
                                        return _c(
                                          "div",
                                          {
                                            key: rightIndex,
                                            staticClass:
                                              "col-span-9 col-start-4 first:col-start-0",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.textOfEnum(
                                                    _vm.rightName,
                                                    right.right_type
                                                  )
                                                ) +
                                                " "
                                            ),
                                            right.exclusive_type === 1
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-red-600",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "pages.commons.quotes.exclusive"
                                                        )
                                                      ) + " "
                                                    ),
                                                  ]
                                                )
                                              : right.exclusive_type === 2
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "pages.commons.quotes.nonexclusive"
                                                      )
                                                    ) + " "
                                                  ),
                                                ])
                                              : right.exclusive_type === 3
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "pages.commons.quotes.holdback"
                                                      )
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.textOfEnum(
                                                          _vm.rightHoldbackLength,
                                                          right.holdback_length
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ])
                                              : _vm._e(),
                                            [4, 5, 8, 9].includes(
                                              right.right_type
                                            )
                                              ? [
                                                  _c("span", [
                                                    _vm._v(
                                                      "Will play/air " +
                                                        _vm._s(
                                                          right.air_run_count
                                                        ) +
                                                        " times."
                                                    ),
                                                  ]),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid grid-cols-12 gap-x-6 mb-2",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "col-span-3 text-right" },
                                      [_vm._v(_vm._s(_vm.chResponseTitle))]
                                    ),
                                    _vm._l(
                                      qm.rights,
                                      function (right, rightIndex) {
                                        return _c(
                                          "div",
                                          {
                                            key: rightIndex,
                                            staticClass:
                                              "col-span-9 col-start-4 first:col-start-0",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.textOfEnum(
                                                    _vm.rightName,
                                                    right.right_type
                                                  )
                                                ) +
                                                " "
                                            ),
                                            right.exclusive_type === 1
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-red-600",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "pages.commons.quotes.exclusive"
                                                        )
                                                      ) + " "
                                                    ),
                                                  ]
                                                )
                                              : right.exclusive_type === 2
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "pages.commons.quotes.nonexclusive"
                                                      )
                                                    ) + " "
                                                  ),
                                                ])
                                              : right.exclusive_type === 3
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "pages.commons.quotes.holdback"
                                                      )
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.textOfEnum(
                                                          _vm.rightHoldbackLength,
                                                          right.holdback_length
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ])
                                              : _vm._e(),
                                            [4, 5, 8, 9].includes(
                                              right.right_type
                                            )
                                              ? [
                                                  _c("span", [
                                                    _vm._v(
                                                      "Will play/air " +
                                                        _vm._s(
                                                          right.air_run_count
                                                        ) +
                                                        " times."
                                                    ),
                                                  ]),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _c("TermsCompareItem", {
                      attrs: {
                        title: _vm.$t("commons.fields.channels"),
                        outline: _vm.quote.channels
                          .map(function (channel) {
                            return channel.name
                          })
                          .join(","),
                        target: _vm.quoteMovies[quoteIndex].channels,
                        original: _vm.quote.channels,
                        compareKey: ["uuid"],
                        hiddenIcon: _vm.isQuoteSet,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid grid-cols-12 gap-x-6 mb-2",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "col-span-3 text-right" },
                                      [_vm._v(_vm._s(_vm.vodRequestTitle))]
                                    ),
                                    _c("div", { staticClass: "col-span-9" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.channelNamesFromChannels(
                                            _vm.quote.channels
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid grid-cols-12 gap-x-6 mb-2",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "col-span-3 text-right" },
                                      [_vm._v(_vm._s(_vm.chResponseTitle))]
                                    ),
                                    _c("div", { staticClass: "col-span-9" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.channelNamesFromChannels(
                                            qm.channels
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    !_vm.isQuoteSet
                      ? _c("TermsCompareItem", {
                          attrs: {
                            title: _vm.$t(
                              "commons.fields.license_period_air_count"
                            ),
                            outline:
                              _vm.getDateString(
                                _vm.quote.license_start_at,
                                false
                              ) +
                              " - " +
                              _vm.getDateString(
                                _vm.quote.license_end_at,
                                false
                              ),
                            original: _vm.quote,
                            compareKeys: ["license_start_at", "license_end_at"],
                            target: _vm.quoteMovies[quoteIndex],
                            hiddenIcon: _vm.isQuoteSet,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid grid-cols-12 gap-x-6 mb-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-3 text-right",
                                          },
                                          [_vm._v(_vm._s(_vm.vodRequestTitle))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-span-9" },
                                          [
                                            _vm._v(
                                              " From: " +
                                                _vm._s(
                                                  _vm.getDateString(
                                                    _vm.quote.license_start_at,
                                                    false
                                                  )
                                                ) +
                                                " To: " +
                                                _vm._s(
                                                  _vm.getDateString(
                                                    _vm.quote.license_end_at,
                                                    false
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid grid-cols-12 gap-x-6 mb-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-3 text-right",
                                          },
                                          [_vm._v(_vm._s(_vm.chResponseTitle))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-span-9" },
                                          [
                                            _vm._v(
                                              " From: " +
                                                _vm._s(
                                                  _vm.getDateString(
                                                    qm.license_start_at,
                                                    false
                                                  )
                                                ) +
                                                " To: " +
                                                _vm._s(
                                                  _vm.getDateString(
                                                    qm.license_end_at,
                                                    false
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      : _vm._e(),
                    _vm.quote.screenshot_count > 0
                      ? _c("TermsCompareItem", {
                          attrs: {
                            target: _vm.quoteMovies[quoteIndex],
                            original: _vm.quote,
                            title: _vm.$t("commons.fields.screenshot"),
                            outline:
                              "" +
                              _vm.quote.screenshot_count +
                              _vm.$t("pages.commons.quotes.count") +
                              " [" +
                              _vm.fileFormatString(
                                _vm.quote.screenshot_format
                              ) +
                              "] " +
                              _vm.fileSizeString(_vm.quote.screenshot_size),
                            compareKeys: [
                              "screenshot_count",
                              "screenshot_format",
                              "screenshot_size",
                            ],
                            hiddenIcon: _vm.isQuoteSet,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid grid-cols-12 gap-x-6 mb-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-3 text-right",
                                          },
                                          [_vm._v(_vm._s(_vm.vodRequestTitle))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-span-9" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.quote.screenshot_count
                                                ) +
                                                " screenshots in " +
                                                _vm._s(
                                                  _vm.fileFormatString(
                                                    _vm.quote.screenshot_format
                                                  )
                                                ) +
                                                " format. " +
                                                _vm._s(
                                                  _vm.fileSizeString(
                                                    _vm.quote.screenshot_size
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid grid-cols-12 gap-x-6 mb-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-3 text-right",
                                          },
                                          [_vm._v(_vm._s(_vm.chResponseTitle))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-span-9" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(qm.screenshot_count) +
                                                " screenshots in " +
                                                _vm._s(
                                                  _vm.fileFormatString(
                                                    qm.screenshot_format
                                                  )
                                                ) +
                                                " format. " +
                                                _vm._s(
                                                  _vm.fileSizeString(
                                                    qm.screenshot_size
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      : _vm._e(),
                    _vm.quote.poster_visual_format > 0
                      ? _c("TermsCompareItem", {
                          attrs: {
                            target: qm,
                            original: _vm.quote,
                            title: _vm.$t("commons.fields.poster_visual"),
                            outline:
                              _vm.$t("commons.fields.required") +
                              "[" +
                              _vm.fileFormatString(
                                _vm.quote.poster_visual_format
                              ) +
                              "] " +
                              _vm.fileSizeString(_vm.quote.poster_visual_size),
                            compareKeys: [
                              "poster_visual_format",
                              "poster_visual_size",
                            ],
                            hiddenIcon: _vm.isQuoteSet,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid grid-cols-12 gap-x-6 mb-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-3 text-right",
                                          },
                                          [_vm._v(_vm._s(_vm.vodRequestTitle))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-span-9" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.fileFormatString(
                                                    _vm.quote
                                                      .poster_visual_format
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm.fileSizeString(
                                                    _vm.quote.poster_visual_size
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid grid-cols-12 gap-x-6 mb-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-3 text-right",
                                          },
                                          [_vm._v(_vm._s(_vm.chResponseTitle))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-span-9" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.fileFormatString(
                                                    qm.poster_visual_format
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm.fileSizeString(
                                                    qm.poster_visual_size
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      : _vm._e(),
                    _vm.quote.logo_visual_format > 0
                      ? _c("TermsCompareItem", {
                          attrs: {
                            target: qm,
                            original: _vm.quote,
                            title: _vm.$t("commons.fields.logo_visual"),
                            outline:
                              _vm.$t("commons.fields.required") +
                              "[" +
                              _vm.fileFormatString(
                                _vm.quote.logo_visual_format
                              ) +
                              "] " +
                              _vm.fileSizeString(_vm.quote.logo_visual_size),
                            compareKeys: [
                              "logo_visual_format",
                              "logo_visual_size",
                            ],
                            hiddenIcon: _vm.isQuoteSet,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid grid-cols-12 gap-x-6 mb-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-3 text-right",
                                          },
                                          [_vm._v(_vm._s(_vm.vodRequestTitle))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-span-9" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.fileFormatString(
                                                    _vm.quote.logo_visual_format
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm.fileSizeString(
                                                    _vm.quote.logo_visual_size
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid grid-cols-12 gap-x-6 mb-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-3 text-right",
                                          },
                                          [_vm._v(_vm._s(_vm.chResponseTitle))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-span-9" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.fileFormatString(
                                                    qm.logo_visual_format
                                                  )
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm.fileSizeString(
                                                    qm.logo_visual_size
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      : _vm._e(),
                    _vm.quote.allow_offline_viewing
                      ? _c("TermsCompareItem", {
                          attrs: {
                            target: _vm.quoteMovies[quoteIndex],
                            original: _vm.quote,
                            title: _vm.$t("commons.fields.offline_viewing"),
                            outline: _vm.$t("commons.fields.required"),
                            compareKeys: ["allow_offline_viewing"],
                            hiddenIcon: _vm.isQuoteSet,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid grid-cols-12 gap-x-6 mb-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-3 text-right",
                                          },
                                          [_vm._v(_vm._s(_vm.vodRequestTitle))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-span-9" },
                                          [_vm._v("Need offline viewing")]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid grid-cols-12 gap-x-6 mb-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-3 text-right",
                                          },
                                          [_vm._v(_vm._s(_vm.chResponseTitle))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-span-9" },
                                          [
                                            qm.allow_offline_viewing
                                              ? _c("span", [
                                                  _vm._v(
                                                    " Allow offline viewing. "
                                                  ),
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    " NOT allow offline viewing. "
                                                  ),
                                                ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      : _vm._e(),
                    _c("div", { staticClass: "bg-light-grey text-left my-2" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "grid grid-cols-12 col-gap-6 p-2 border-dashed border-b border-brown-grey",
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "col-start-1 col-span-3 text-right",
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "pages.commons.quotes.delivery_method"
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c("p", { staticClass: "col-span-7" }, [
                            _vm._v(
                              _vm._s(
                                _vm.getDeliveryMethod(_vm.quote.delivery_method)
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "p-2" }, [
                        _c(
                          "div",
                          { staticClass: "grid grid-cols-12 col-gap-6" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-start-4 col-span-8 text-left mr-3",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getDeliveryMethodDetail(_vm.quote)
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    !_vm.isQuoteSet
                      ? _c("TermsCompareItem", {
                          attrs: {
                            target: _vm.quoteMovies[quoteIndex],
                            original: _vm.quote,
                            title: _vm.$t(
                              "commons.fields.delivery_possible_by"
                            ),
                            outline:
                              "" + _vm.getDateString(_vm.quote.delivery_at),
                            compareKeys: ["delivery_at"],
                            hiddenIcon: _vm.isQuoteSet,
                            beforeDate: true,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid grid-cols-12 gap-x-6 mb-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-3 text-right",
                                          },
                                          [_vm._v(_vm._s(_vm.vodRequestTitle))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-span-9" },
                                          [
                                            _vm._v(
                                              "Must deliver by " +
                                                _vm._s(
                                                  _vm.getDateString(
                                                    _vm.quote.delivery_at
                                                  )
                                                )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "grid grid-cols-12 gap-x-6 mb-2",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-3 text-right",
                                          },
                                          [_vm._v(_vm._s(_vm.chResponseTitle))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-span-9" },
                                          [
                                            _vm._v(
                                              "Can deliver by " +
                                                _vm._s(
                                                  _vm.getDateString(
                                                    qm.delivery_at
                                                  )
                                                )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      : _vm._e(),
                    _c("div", { staticClass: "bg-light-grey text-left my-2" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "grid grid-cols-12 gap-x-6 p-2 border-dashed border-b border-brown-grey",
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "col-start-1 col-span-3 text-right",
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("commons.fields.message"))
                                ),
                              ]),
                            ]
                          ),
                          _c("p", { staticClass: "col-span-7" }),
                        ]
                      ),
                      _c("div", { staticClass: "p-2" }, [
                        _c(
                          "div",
                          { staticClass: "grid grid-cols-12 gap-x-6" },
                          [
                            _vm.quote.message
                              ? [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "col-start-2 col-span-5 text-justify break-words whitespace-pre-wrap",
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.quote.message) + " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "col-span-5 text-justify break-words whitespace-pre-wrap",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.translatedMessage) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              : _c(
                                  "p",
                                  {
                                    staticClass:
                                      "col-start-5 col-span-3 text-center",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "pages.commons.quotes.message_not_exist"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                          ],
                          2
                        ),
                      ]),
                    ]),
                  ],
                  2
                ),
              ],
              1
            )
          }),
          0
        ),
        _vm.loginUserRole === "consumer" && _vm.quote && _vm.quote.status === 3
          ? _c(
              "div",
              { staticClass: "clear-both mx-auto w-4/12" },
              [
                _c(
                  "LinkButton",
                  {
                    attrs: {
                      classList: "px-2 py-3 mb-4",
                      processing: _vm.isPurchasing,
                    },
                    on: { clickButton: _vm.purchaseMovies },
                  },
                  [_vm._v(" PURCHASE ALL ")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.displayTrash && _vm.quote && _vm.quote.status
          ? _c(
              "div",
              { staticClass: "clear-both mx-auto w-4/12" },
              [
                _c(
                  "LinkButton",
                  {
                    attrs: {
                      classList: "px-2 py-3 mb-4",
                      disabled: _vm.quote && _vm.quote.status > 4,
                    },
                    on: {
                      clickButton: function ($event) {
                        return _vm.$modal.show("checkDelete")
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.allDeleteTitle) + " ")]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("NoticeModal", {
        attrs: {
          modalName: "successFailModal",
          contents: _vm.successFailMessage,
          buttons: ["OK"],
        },
      }),
      _c("NoticeModal", {
        attrs: {
          modalName: "checkDelete",
          title: _vm.quoteDeleteTitle,
          contents: _vm.quoteDeleteContents,
          buttons: ["取り消し", "OK"],
        },
        on: {
          click: function ($event) {
            return _vm.cancelOrDeleteQuote()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }